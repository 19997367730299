import React, { useState, useEffect, useRef } from "react";
import "./IdleTimeOut.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { onLogout } from "../authentication/authenticationSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export default function IdleTimeOut() {
  const { t } = useTranslation("language");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authentication);
  const timeoutDuration =
    user && user.length > 0 ? user[0].appIdleTimeMinutes * 60000 : 600000; // Default to 10 minutes if user data is not available
  const [logoutTimer, setLogoutTimer] = useState(null);
  const [open, setOpen] = useState(false);
  const handleStayLoggedInRef = useRef();

  useEffect(() => {
    let idleTimer;

    const resetTimer = () => {
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }

      idleTimer = setTimeout(() => {
        handleOnIdle();
      }, timeoutDuration);
      setLogoutTimer(idleTimer);
    };

    const handleOnIdle = () => {
      setOpen(true);
      setLogoutTimer(
        setTimeout(() => {
          handleLogout();
        }, 30000) // 30 seconds
      );
    };

    handleStayLoggedInRef.current = () => {
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
      resetTimer();
      setOpen(false);
    };

    const handleLogout = () => {
      dispatch(onLogout({ method: "GET" }));
    };

    // Initial setup
    resetTimer();

    // Cleanup function
    return () => {
      clearTimeout(idleTimer);
      clearTimeout(logoutTimer);
    };
  }, [1000]);

  const handleStayLoggedIn = () => {
    if (handleStayLoggedInRef.current) {
      handleStayLoggedInRef.current();
    }
  };

  return (
    <div className="IdleTimeOut">
      <Dialog open={open}>
        <DialogTitle>{t("Global.Session")}</DialogTitle>
        <DialogContent>{t("Global.Timeout_Session")}</DialogContent>
        <DialogActions sx={{ padding: "12px 24px !important" }}>
          <Button
            variant="contained"
            onClick={handleStayLoggedIn}
            type="submit"
          >
            {t("Global.Stay_Logged_In")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
